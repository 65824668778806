/* eslint-disable */
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
 } from "@chakra-ui/react";
import { UserContext } from "App";
import { UserRoles } from "utils/Constants"

export function SidebarLinks(props) {
  const [userContext, _] = useContext(UserContext);
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (route) => {
    var pathname = route.layout.toLowerCase() + route.path.toLowerCase()
    return location.pathname.includes(pathname);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category && userContext?.roles.includes(UserRoles.Admin)) {
        return (
          <>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                  <AccordionButton px="10px" _focus={false} _expanded={{ color: brandColor }} color={textColor}>
                    <Flex w='100%' alignItems='center' justifyItems='center'>
                      <Flex me="18px">{route.icon}</Flex>
                      <Text
                        me='auto'
                        >
                      {route.name}
                    </Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel pb={4}>
                {createLinks(route.items)}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        );
      } else if (route.childComponent) {
        return null;
      }
      else if (
        // (route.layout === "" && route.path === "/sign-out") ||
        (userContext?.roles.includes(UserRoles.Customer) && (route.layout === "/order" || route.layout === "/organisation")) ||
        (userContext?.roles.includes(UserRoles.ProductionStaff) && (route.layout === "/staff")) ||
        (userContext?.roles.includes(UserRoles.Driver) && (route.layout === "/delivery")) ||
        (userContext?.roles.includes(UserRoles.Admin) && (route.layout === "/admin" || route.layout === "/rtl"))
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Flex
                      color={
                        activeRoute(route)
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Flex>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route)
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route)
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route)
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
