import { React, useEffect, useState } from "react";
import {
    Button,
    Flex,
    Text,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    createStandaloneToast,
    useColorModeValue,
} from "@chakra-ui/react";
import { Field } from 'formik';
import { useParams, useHistory } from "react-router-dom";
import { getProductSiteID, createProductSite, updateProductSite } from "apis/productSite";
import FormContainer from "components/admin/FormContainer";
import moment from "moment";
import { MomentFormatDateOnly } from "utils/Constants"

export default function SiteForm(props) {
    const toast = createStandaloneToast();
    const { action, id } = useParams();
    const viewOnly = action === "view"
    const history = useHistory();
    const backUrl = "/admin/sites";
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [productSite, setProductSite] = useState(null);
    const [exceptionDate, setExceptionDate] = useState([]);
    const [hover, setHover] = useState();

    const exceptionDateBtnBg = useColorModeValue( "white", "secondaryGray.900");

    const initValues ={
        name: "",
        address: "",
        exceptionDates: [],
    };

    useEffect(() => {
        if (action && id) {
            getProductSiteID(id).then(res => {
                setProductSite(res.data)
                setExceptionDate(res.data.exceptionDates)
            }).catch(err => {
                console.log(err);
            })
        }
    }, []);

    function validateName(value) {
        let error
        if (!value) {
            error = "Name is required"
        }
        return error
    }

    function validateAddress(value) {
        let error
        if (!value) {
            error = "Address is required"
        }
        return error
    }

    return ((!action || productSite) && (
        <FormContainer
            backUrl={backUrl}
            initialValues={action ? productSite : initValues}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                values.exceptionDates = exceptionDate
                if (!action) {
                    createProductSite(values).then(res => {
                        toast({
                            title: 'Successful',
                            description: `Production Site "${values.name}" has been created`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                } else if (action === "edit") {
                    updateProductSite(values.id, values).then(res => {
                        toast({
                            title: 'Successful',
                            description: `Production Site ${values.name} has been updated`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }}
        >

            <Flex px='25px' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    Production Site
                </Text>
            </Flex>

            <Flex px='24px' my="24px" >
                <Field name="name" validate={validateName} alignItems={"end"}>
                    {({ field, form }) => (
                        <FormControl px='24px' isInvalid={form.errors.name && form.touched.name}>
                            <FormLabel htmlFor="name">Site Name</FormLabel>
                            <Input
                                {...field}
                                id="name"
                                placeholder=""
                                readOnly={viewOnly}
                            />
                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>

                <Field name="address" validate={validateAddress} alignItems={"end"}>
                    {({ field, form }) => (
                        <FormControl px='24px' isInvalid={form.errors.address && form.touched.address}>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <Input
                                {...field}
                                id="address"
                                placeholder=""
                                readOnly={viewOnly}
                            />
                            <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>
            </Flex>

            <Flex px='25px' my='24px' align='center'>
                <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='100%'>
                    Exception Dates
                </Text>
            </Flex>
            {!viewOnly && (<Field name="exceptionDate">
                {({ field, form }) => (
                    <Flex px='24px' my="24px" gap="48px" flexDirection={{ base: "column", md: "row" }}>
                        <FormControl px='24px'>
                            <Input
                                type="date"
                                {...field}
                                id="exceptionDate"
                                placeholder=""
                                readOnly={viewOnly}
                            />
                        </FormControl>
                        <Flex width={{ base: "100%", md: "20%" }} px={{ base: "24px", md: "0px" }}>
                            <Button
                                variant="brand"
                                width={{ base: "100%" }}
                                onClick={() => {
                                    if (field.value) {
                                        setExceptionDate([field.value, ...exceptionDate.filter(existingTime => existingTime !== field.value)].sort());
                                    }
                                }}>
                                Add Time
                            </Button>
                        </Flex>

                    </Flex>
                )}
            </Field>)}

            <Flex flexDirection={"row"} gap={"24px"} px="48px">
                {
                    exceptionDate.map((date, index) => (
                        <Button
                            readOnly={viewOnly}
                            minWidth="150px"
                            onClick={() => setExceptionDate(exceptionDate.filter(item => item !== date))}
                            variant='brand'
                            onMouseEnter={() => { setHover(index) }}
                            onMouseLeave={() => { setHover(null) }}
                            bg={exceptionDateBtnBg}
                            color={textColor}
                            outlineColor={"gray.200"}
                            _hover={{ bg: 'red.600', color: 'white', outlineColor: "red.600" }}
                            pointerEvents={(viewOnly) && "none"}
                        >
                            {hover === index ? "Delete" : moment(date).format(MomentFormatDateOnly)}
                        </Button>
                    ))
                }
            </Flex>

        </FormContainer>
    )
    )
}