import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/providedServices/components/columnsData";
import { getServices, removeService } from "apis/providedServices";
import { deleteImage } from "apis/files";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/providedServices/components/filterBar";

export default function ProvidedServices() {
  useEffect(() => {
    refreshServices();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function refreshServices() {
    getServices()
      .then((res) => {
        for (const data of res.data) {
          data.altText = data.name;
        }
        setUnfilteredData(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteService(id) {
    removeService(id)
      .then(() => {
        let iconUrl = data.find((element) => element.id == id).iconUrl;
        deleteImage(iconUrl)
          .then(() => {
            refreshServices();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="service/view"
        createUrl="service/create"
        disableCallback={deleteService}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
      />
    </Box>
  );
}
