import {
  React,
  useEffect,
  useState,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  Flex,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Select,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import moment from "moment";

import {
  OrderStatus,
  OrderStatusOptions,
  OrderStatusSelectFilterOptions,
  MomentFormatWithTimeZone,
  getSelectOptionObj,
  validateEmpty,
  ImageUploadFileTypes,
} from "utils/Constants";

import { Field, useFormikContext } from "formik";

import { useParams, useHistory, useLocation } from "react-router-dom";

import FormContainer from "components/admin/FormContainer";
import { HSeparator } from "components/separator/Separator";
import Upload from "components/admin/Upload";
import ReactSelect from "components/admin/ReactSelectFormik";

import { adminGetOrder, updateOrder, adminAddOrder } from "apis/order";
import { filesUpload, imageUpload } from "apis/files";
import { getProductSite } from "apis/productSite";
import { getOrganisations } from "apis/organisation";
import { getProductionTimeSlots } from "apis/productionTimeSlot";
import { getProductsWithoutRules } from "apis/product";
import AuthImage from "components/auth/AuthImage"
import FilesListField from "./components/FilesListField"

const FormFields = forwardRef((props, ref) => {
  const {
    products,
    productionSites,
    organisations,
    timeSlots,
    files,
    setFiles,
    sharedFiles,
    setSharedFiles,
    internalFiles,
    setInternalFiles,
    imageFile,
    setImageFile,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const { setValues, values } = useFormikContext();

  const deliveredImageUrl = useMemo(
    () =>
      imageFile.length > 0
        ? imageFile[0]
        : values?.deliveredImageUrl
          ? values.deliveredImageUrl
          : null,
    [imageFile]
  );

  useImperativeHandle(ref, () => {
    return {
      setValues,
    };
  });
  return (
    <>
      <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="20%">
        Order Details
      </Text>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px" my="24px">
        <Field name="productId" validate={validateEmpty}>
          {({ field, form }) => (
            <FormControl
              flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
              id="productId"
              isInvalid={form.errors.productId && form.touched.productId}
            >
              <FormLabel htmlFor="productId">Product</FormLabel>
              <ReactSelect
                {...field}
                placeholder="Select a product"
                options={products.map(getSelectOptionObj)}
              />
              <FormErrorMessage>{form.errors.productId}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name="productionSiteId" validate={validateEmpty}>
          {({ field, form }) => (
            <FormControl
              flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
              id="productionSiteId"
              isInvalid={
                form.errors.productionSiteId && form.touched.productionSiteId
              }
            >
              <FormLabel htmlFor="productionSiteId">Production Site</FormLabel>
              <ReactSelect
                {...field}
                placeholder="Select a production site"
                options={productionSites.map(getSelectOptionObj)}
              />
              <FormErrorMessage>
                {form.errors.productionSiteId}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name="orderOrgainsationId" validate={validateEmpty}>
          {({ field, form }) => (
            <FormControl
              flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
              id="orderOrgainsationId"
              isInvalid={
                form.errors.orderOrgainsationId &&
                form.touched.orderOrgainsationId
              }
            >
              <FormLabel htmlFor="orderOrgainsationId">Organisation</FormLabel>
              <ReactSelect
                {...field}
                placeholder="Select an organisation"
                options={organisations.map(getSelectOptionObj)}
              />
              <FormErrorMessage>
                {form.errors.orderOrgainsationId}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name="quantity" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="quantity"
                isInvalid={form.errors.quantity && form.touched.quantity}
              >
                <FormLabel htmlFor="quantity">Quantity</FormLabel>
                <NumberInput
                  min={0}
                  {...field}
                  onChange={(v) => form.setFieldValue("quantity", v)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="deliveryDate" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="deliveryDate"
                isInvalid={
                  form.errors.deliveryDate && form.touched.deliveryDate
                }
              >
                <FormLabel htmlFor="deliveryDate">Delivery Date</FormLabel>
                <Input type="date" {...field} />
                <FormErrorMessage>{form.errors.deliveryDate}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="pickUpTime" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="pickUpTime"
                isInvalid={form.errors.pickUpTime && form.touched.pickUpTime}
              >
                <FormLabel htmlFor="pickUpTime">Pick Up Time</FormLabel>
                <Input type="time" {...field} />
                <FormErrorMessage>{form.errors.pickUpTime}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="deliveryTime" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="deliveryTime"
                isInvalid={
                  form.errors.deliveryTime && form.touched.deliveryTime
                }
              >
                <FormLabel htmlFor="deliveryTime">Delivery Time</FormLabel>
                <Input type="time" {...field} />
                <FormErrorMessage>{form.errors.deliveryTime}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="timeSlotId" validate={validateEmpty}>
          {({ field, form }) => (
            <FormControl
              flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
              id="timeSlotId"
              isInvalid={form.errors.timeSlotId && form.touched.timeSlotId}
            >
              <FormLabel htmlFor="timeSlotId">Time Slot</FormLabel>
              <ReactSelect
                {...field}
                placeholder="Select a time slot"
                options={timeSlots.map(getSelectOptionObj)}
              />
              <FormErrorMessage>{form.errors.timeSlotId}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name="orderPerson" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="orderPerson"
                isInvalid={form.errors.orderPerson && form.touched.orderPerson}
              >
                <FormLabel htmlFor="orderPerson">Order Person</FormLabel>
                <Input {...field} />
                <FormErrorMessage>{form.errors.orderPerson}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="purchaseOrder">
          {({ field }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="purchaseOrder"
              >
                <FormLabel htmlFor="purchaseOrder">Purchase Order</FormLabel>
                <Input {...field} placeholder="N/A" />
              </FormControl>
            );
          }}
        </Field>

        <Field name="invoiceNumber">
          {({ field }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="invoiceNumber"
              >
                <FormLabel htmlFor="invoiceNumber">Invoice Number</FormLabel>
                <Input {...field} placeholder="N/A" />
              </FormControl>
            );
          }}
        </Field>

        <Field
          name="status"
          validate={(val) => !(val !== null && val !== undefined)}
        >
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="status"
                isInvalid={form.errors.status && form.touched.status}
              >
                <FormLabel htmlFor="status">Status</FormLabel>
                <ReactSelect
                  {...field}
                  placeholder="Select a status"
                  options={OrderStatusOptions}
                  filterOption={OrderStatusSelectFilterOptions}
                />
                <FormErrorMessage>{form.errors.status}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </SimpleGrid>

      <HSeparator />

      <Text
        color={textColor}
        fontSize="22px"
        fontWeight="700"
        lineHeight="20%"
        mb="20px"
        mt="30px"
      >
        Patient Details
      </Text>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px" my="24px">
        <Field name="patientDetails.name" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                id="patientDetails.name"
                isInvalid={
                  form.errors.patientDetails?.name &&
                  form.touched.patientDetails?.name
                }
              >
                <FormLabel htmlFor="patientDetails.name">Name</FormLabel>
                <Input {...field} />
                <FormErrorMessage>
                  {form.errors.patientDetails?.name}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="patientDetails.weightInKG" validate={validateEmpty}>
          {({ field, form }) => (
            <FormControl
              id="patientDetails.weightInKG"
              isInvalid={
                form.errors.patientDetails?.weightInKG &&
                form.touched.patientDetails?.weightInKG
              }
            >
              <FormLabel htmlFor="patientDetails.weightInKG">
                Weight (In KG)
              </FormLabel>
              <NumberInput
                min={0}
                {...field}
                onChange={(v) =>
                  form.setFieldValue("patientDetails.weightInKG", v)
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {form.errors.patientDetails?.weightInKG}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="patientDetails.dateOfBirth" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                flexBasis={{ base: "100%", md: "45%", lg: "23%" }}
                id="patientDetails.dateOfBirth"
                isInvalid={
                  form.errors.patientDetails?.dateOfBirth &&
                  form.touched.patientDetails?.dateOfBirth
                }
              >
                <FormLabel htmlFor="patientDetails.dateOfBirth">
                  Date of birth
                </FormLabel>
                <Input type="date" {...field} />
                <FormErrorMessage>
                  {form.errors.patientDetails?.dateOfBirth}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
        <Field name="patientDetails.doctorName" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                id="patientDetails.doctorName"
                isInvalid={
                  form.errors.patientDetails?.doctorName &&
                  form.touched.patientDetails?.doctorName
                }
              >
                <FormLabel htmlFor="patientDetails.doctorName">
                  Doctor's name
                </FormLabel>
                <Input {...field} />
                <FormErrorMessage>
                  {form.errors.patientDetails?.doctorName}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </SimpleGrid>

      <HSeparator />

      <Text
        color={textColor}
        fontSize="22px"
        fontWeight="700"
        lineHeight="20%"
        mb="20px"
        mt="30px"
      >
        Addtional Information
      </Text>

      <SimpleGrid columns={1} gap="20px" my="24px">
        <Field name="additionalInformation">
          {({ field }) => (
            <FormControl id="additionalInformation">
              <FormLabel htmlFor="additionalInformation">
                Customer notes
              </FormLabel>
              <Textarea {...field} placeholder="N/A"></Textarea>
            </FormControl>
          )}
        </Field>
        <Field name="internalNotes">
          {({ field }) => (
            <FormControl id="internalNotes">
              <FormLabel htmlFor="internalNotes">Internal Notes</FormLabel>
              <Textarea {...field} placeholder="N/A"></Textarea>
            </FormControl>
          )}
        </Field>
      </SimpleGrid>

      <HSeparator />

      <Text
        color={textColor}
        fontSize="22px"
        fontWeight="700"
        lineHeight="20%"
        mb="20px"
        mt="30px"
      >
        Files
      </Text>

      <Field name="files">
        {({ form, field }) => (<FilesListField
          label="Customer files"
          borderColor={borderColor}
          files={files}
          setFiles={setFiles}
          form={form}
          field={field}
          values={values}
        />)}
      </Field>

      <Field name="sharedFiles">
        {({ field, form }) => (
          <FilesListField
            label="Shared files"
            borderColor={borderColor}
            files={sharedFiles}
            setFiles={setSharedFiles}
            form={form}
            field={field}
            values={values}
          />)}
      </Field>


      <Field name="internalFiles">
        {({ field, form }) => (
          <FilesListField
            label="Internal files"
            borderColor={borderColor}
            files={internalFiles}
            setFiles={setInternalFiles}
            form={form}
            field={field}
            values={values}
          />)}
      </Field>

      <SimpleGrid
        border="1px"
        borderColor={borderColor}
        borderRadius="5px"
        columns={{ base: 1, md: 2 }}
        gap="20px"
        my="24px"
      >
        <FormControl p="10px" id="deliveredImageUrl">
          <FormLabel>Delivery Image</FormLabel>
          {deliveredImageUrl && (
            <Flex pb="10px" px="25px" mt="24px" align="center" justify="center">
              <AuthImage maxH="300px" src={deliveredImageUrl} isNotUploaded={imageFile.length > 0} />
            </Flex>
          )}
        </FormControl>

        <Upload
          p="0"
          mb="0"
          onSelected={(selectedFiles) => setImageFile(selectedFiles)}
          multiple={false}
          selectedFileListTitles="The selected image will replace the old image"
          files={imageFile}
          title={
            values?.deliveredImageUrl || imageFile.length > 0
              ? "Change Image"
              : "Upload Image"
          }
          description="One image file only"
          fileTypes={ImageUploadFileTypes}
        />
      </SimpleGrid>
    </>
  );
});

export default function OrderForm(props) {
  const toast = createStandaloneToast();
  const { id } = useParams();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [sharedFiles, setSharedFiles] = useState([]);
  const [internalFiles, setInternalFiles] = useState([]);
  const [imageFile, setImageFile] = useState([]);

  const backUrl = id ? `/admin/order/view/${id}` : "/admin/orders";

  const [orderData, setOrderData] = useState(null);
  const [formData, setFormData] = useState(null);

  const [changeLogs, setChangeLogs] = useState([]);

  const [products, setProducts] = useState([]);
  const [productionSites, setProductionSites] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const [overlapMgs, setOverlapMgs] = useState("");
  const [submittedVal, setSubmittedVal] = useState(null);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const changeLogQueryVal = query.get("changeLog");

  const childRef = useRef();

  const {
    isOpen: isDialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose,
  } = useDisclosure();

  const cancelDialogRef = useRef();

  const initValues = {
    purchaseOrder: "",
    invoiceNumber: "",
    orderPerson: "",
    quantity: 1,
    deliveredImageUrl: "",
    deliveryDate: "",
    deliveryTime: "",
    pickUpTime: "",
    status: OrderStatus.Pending,
    patientDetails: {
      weightInKG: 0,
      name: "",
      dateOfBirth: "",
      doctorName: "",
    },
    additionalInformation: "",
    internalNotes: "",
    files: [],
    sharedFiles: [],
    internalFiles: [],
    productId: "",
    productionSiteId: "",
    orderOrgainsationId: "",
    timeSlotId: "",
  };

  useEffect(() => {
    getProductsWithoutRules().then((res) => setProducts(res.data));
    getOrganisations().then((res) => setOrganisations(res.data));
    getProductionTimeSlots().then((res) => setTimeSlots(res.data));
    getProductSite().then((res) => setProductionSites(res.data));

    if (id) {
      adminGetOrder(id)
        .then((res) => {
          const order = res.data;

          order.deliveryDate = moment(order.deliveryDate).format("YYYY-MM-DD");
          setOrderData(order);

          const reversedOrderChangeLogs = order.changeLogs.reverse();
          setChangeLogs(reversedOrderChangeLogs);

          const validChangeLogQueryValue =
            changeLogQueryVal != null &&
            !(
              isNaN(changeLogQueryVal) ||
              changeLogQueryVal === "" ||
              changeLogQueryVal < 0
            ) &&
            changeLogQueryVal <= order.changeLogs.length - 1;

          if (validChangeLogQueryValue) {
            const selectedChangeLog = structuredClone(
              reversedOrderChangeLogs[changeLogQueryVal]
            );

            selectedChangeLog.deliveryDate = moment(
              selectedChangeLog.deliveryDate
            ).format("YYYY-MM-DD");

            selectedChangeLog.id = order.id;

            setFormData(selectedChangeLog);
          } else {
            setFormData(order);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [changeLogQueryVal, id]);

  function changeLogSelectionChanged(e) {
    if (e.target.value === "") {
      childRef.current?.setValues(orderData);
    } else {
      const selectedChangeLog = structuredClone(changeLogs[e.target.value]);
      selectedChangeLog.deliveryDate = moment(
        selectedChangeLog.deliveryDate
      ).format("YYYY-MM-DD");
      selectedChangeLog.id = orderData.id;
      childRef.current?.setValues(selectedChangeLog);
    }
  }

  function requestUpdateOrder(values, actions) {
    updateOrder(values.id, values)
      .then(() => {
        toast({
          title: "Successful",
          description: "Order details updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push(backUrl);
      })
      .catch((err) => {
        console.log(err);
        actions.setSubmitting(false);
      });
  }

  function requestAddOrder(values, actions) {
    adminAddOrder(values)
      .then((res) => {
        if (res.errorCode == 0) {
          toast({
            title: "Successful",
            description: "Order details updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        } else {
          setOverlapMgs(res.message);
          setSubmittedVal({ values, actions });
          onDialogOpen();
          actions.setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        actions.setSubmitting(false);
      });
  }

  function forceRequestAddOrder() {
    submittedVal.values.forcedAdd = true;
    requestAddOrder(submittedVal.values, submittedVal.actions);
    onDialogClose();
  }

  async function uploadFiles(files) {
    let filesForm = new FormData();
    for (let file of files) {
      filesForm.append("files", file);
    }
    const filesRes = await filesUpload(filesForm);
    return filesRes.data;
  }

  async function handleSubmit(values, actions) {
    values.status = Number(values.status);
    try {
      if (files.length > 0) {
        const uploaded = await uploadFiles(files);
        values.files.push(...uploaded);
      }

      if (sharedFiles.length > 0) {
        const uploaded = await uploadFiles(sharedFiles);
        values.sharedFiles.push(...uploaded);
      }

      if (internalFiles.length > 0) {
        const uploaded = await uploadFiles(internalFiles);
        values.internalFiles.push(...uploaded);
      }

      if (imageFile.length > 0) {
        let imageForm = new FormData();
        imageForm.append("image", imageFile[0]);
        var imageRes = await imageUpload(imageForm);
        values.deliveredImageUrl = imageRes.data.name;
      }

      if (id) {
        requestUpdateOrder(values, actions);
      } else {
        requestAddOrder(values, actions);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    (!id || formData) && (
      <>
        <FormContainer
          backUrl={backUrl}
          initialValues={id ? formData : initValues}
          onSubmit={async (v, a) => await handleSubmit(v, a)}
        >
          {id && formData && (
            <Flex align="end" justify="end">
              <FormControl maxW={{ base: "100%", lg: "75%" }}>
                <FormLabel htmlFor="changeLog">Change logs</FormLabel>
                <Select
                  id="changeLog"
                  name="changeLog"
                  size="lg"
                  variant="filled"
                  placeholder={
                    "(Lastest) " +
                    `${orderData.changedBy} [${moment(
                      orderData.changedTime
                    ).format(MomentFormatWithTimeZone)}]`
                  }
                  defaultValue={changeLogQueryVal}
                  onChange={changeLogSelectionChanged}
                >
                  {changeLogs.map((o, i) => (
                    <option key={i} value={i}>{`${o.changedBy} [${moment(
                      o.changedTime
                    ).format(MomentFormatWithTimeZone)}]`}</option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
          )}
          <FormFields
            ref={childRef}
            products={products}
            productionSites={productionSites}
            organisations={organisations}
            timeSlots={timeSlots}
            files={files}
            setFiles={setFiles}
            sharedFiles={sharedFiles}
            setSharedFiles={setSharedFiles}
            internalFiles={internalFiles}
            setInternalFiles={setInternalFiles}
            imageFile={imageFile}
            setImageFile={setImageFile}
          />
        </FormContainer>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelDialogRef}
          onClose={onDialogClose}
          isOpen={isDialogOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>
              Add order on overlapped time slot?
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{overlapMgs}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelDialogRef} onClick={onDialogClose}>
                No
              </Button>
              <Button colorScheme="red" ml={3} onClick={forceRequestAddOrder}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  );
}
