import { React, useEffect, useState } from "react";

import {
  List,
  ListItem,
  ListIcon,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Textarea,
  Link,
  SimpleGrid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
} from "@chakra-ui/react";
import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import RequestChangeDialog from "./components/RequestChangeDialog";
import { MdAttachFile } from "react-icons/md";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import Upload from "components/admin/Upload";
import {
  OrderStatus,
  MomentFormatWithTimeZone,
  validateEmpty,
  PdfUploadFileType,
  MaxFilesUpload,
} from "utils/Constants";
import moment from "moment";

import { DeleteIcon } from "@chakra-ui/icons";

import AuthLink from "components/auth/AuthLink";

//API
import { filesUpload } from "apis/files";
import { customerGetOrder, customerRequestChange } from "apis/order";

export default function OrderEdit() {
  const history = useHistory();
  const toast = createStandaloneToast();
  const { id } = useParams();
  const backUrl = "/order/view/" + id;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const [orderData, setData] = useState(null);
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function removeFile(form, field, index) {
    const { value } = field;
    value.splice(index, 1);
    form.setFieldValue(field.name, value);
  }

  function getMaxFilesUpload(fileLength) {
    return MaxFilesUpload - fileLength;
  }

  async function handleSubmit(values) {
    setIsSubmitting(true);

    try {
      if (files.length > 0) {
        let filesForm = new FormData();
        for (let file of files) {
          filesForm.append("files", file);
        }
        let filesRes = await filesUpload(filesForm);
        values.files.push(...filesRes.data);
      }

      await customerRequestChange(values.id, values);

      toast({
        title: "Successful",
        description: "Order details updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      history.push(backUrl);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  }

  function getData() {
    customerGetOrder(id)
      .then((res) => {
        var order = res.data;
        const [hour, min, sec] = order.deliveryTime.split(":");
        order.requiredDateTime = moment(order.deliveryDate)
          .add({ hours: hour, minutes: min, seconds: sec })
          .format(MomentFormatWithTimeZone);
        setData(order);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function validateProductQuantity(quantity) {
    return (!quantity && isNaN(quantity)) || Number(quantity) < 1
      ? "Please input a quantity"
      : null;
  }

  return (
    orderData &&
    (orderData.status == OrderStatus.Pending ||
      orderData.status == OrderStatus.AmendmentRequested ? (
      <FormContainer
        hideActionButtons={true}
        backUrl={backUrl}
        initialValues={orderData}
        onSubmit={async (v) => await handleSubmit(v)}
      >
        <Flex px="20px" mb="20px" align="center">
          <Text color={textColor} fontSize="22px" fontWeight="700">
            Updating order details for
            <Text as="i" fontSize="25px" mx="2">
              &quot;{orderData.productName} &quot;
            </Text>
            scheduled at
            <Text as="i" fontSize="25px" mx="2">
              &quot;{orderData.requiredDateTime}&quot;
            </Text>
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap="24px"
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="orderPerson" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="orderPerson"
                  isInvalid={
                    form.errors.orderPerson && form.touched.orderPerson
                  }
                >
                  <FormLabel htmlFor="orderPerson">Order Person</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.orderPerson}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="purchaseOrder">
            {({ field, form }) => {
              return (
                <FormControl id="purchaseOrder">
                  <FormLabel htmlFor="purchaseOrder">Purchase Order</FormLabel>
                  <Input {...field} placeholder="N/A" />
                </FormControl>
              );
            }}
          </Field>
          <Field name="quantity" validate={validateProductQuantity}>
            {({ field, form }) => (
              <FormControl
                id="quantity"
                isInvalid={form.errors.quantity && form.touched.quantity}
              >
                <FormLabel htmlFor="quantity">Quantity</FormLabel>
                <NumberInput
                  min={1}
                  {...field}
                  max={orderData.quantityLimit}
                  onChange={(v) => form.setFieldValue("quantity", v)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>

        <Flex px="20px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="20px"
            fontWeight="700"
            lineHeight="20%"
            mt={3}
          >
            Patient Details
          </Text>
        </Flex>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          gap="20px"
          px="20px"
          mb="20px"
        >
          <Field name="patientDetails.name" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="patientDetails.name"
                  isInvalid={
                    form.errors.patientDetails?.name &&
                    form.touched.patientDetails?.name
                  }
                >
                  <FormLabel htmlFor="patientDetails.name">Name</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>
                    {form.errors.patientDetails?.name}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="patientDetails.weightInKG" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="patientDetails.weightInKG"
                  isInvalid={
                    form.errors.patientDetails?.weightInKG &&
                    form.touched.patientDetails?.weightInKG
                  }
                >
                  <FormLabel htmlFor="patientDetails.weightInKG">
                    Weight (In KG)
                  </FormLabel>
                  <NumberInput
                    min={0}
                    {...field}
                    onChange={(v) =>
                      form.setFieldValue("patientDetails.weightInKG", v)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormErrorMessage>
                    {form.errors.patientDetails?.weightInKG}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="patientDetails.dateOfBirth" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="patientDetails.dateOfBirth"
                  isInvalid={
                    form.errors.patientDetails?.dateOfBirth &&
                    form.touched.patientDetails?.dateOfBirth
                  }
                >
                  <FormLabel htmlFor="patientDetails.dateOfBirth">
                    Date of birth
                  </FormLabel>
                  <Input type="date" {...field} />
                  <FormErrorMessage>
                    {form.errors.patientDetails?.dateOfBirth}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="patientDetails.doctorName" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="patientDetails.doctorName"
                  isInvalid={
                    form.errors.patientDetails?.doctorName &&
                    form.touched.patientDetails?.doctorName
                  }
                >
                  <FormLabel htmlFor="patientDetails.doctorName">
                    Doctor's name
                  </FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>
                    {form.errors.patientDetails?.doctorName}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </SimpleGrid>

        <Flex px="20px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="20px"
            fontWeight="700"
            lineHeight="20%"
            mt={3}
          >
            Addtional Information
          </Text>
        </Flex>

        <Flex px="24px" my="24px">
          <Field name="additionalInformation">
            {({ field, form }) => {
              return (
                <FormControl id="additionalInformation">
                  <Textarea {...field} placeholder="None"></Textarea>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="20px"
          mt="10px"
          mb="4px"
          ml="24px"
        >
          Files
        </Text>

        <Field name="files">
          {({ form, field }) => (
            <SimpleGrid
              border="1px"
              borderColor={borderColor}
              borderRadius="5px"
              columns={{ base: 1, md: 2 }}
              gap="20px"
              m="24px"
            >
              <FormControl p="10px">
                <FormLabel>Customer files</FormLabel>
                <List spacing={3}>
                  {field.value?.map((file, i) => (
                    <ListItem key={i}>
                      <AuthLink
                        href={file.name}
                        icon={MdAttachFile}
                        color="blue.400"
                        isExternal
                        isList
                      >
                        {file.originalName}
                      </AuthLink>
                      <DeleteIcon
                        cursor="pointer"
                        ml="5px"
                        color="red.500"
                        onClick={() => removeFile(form, field, i)}
                      />
                    </ListItem>
                  ))}
                </List>
              </FormControl>

              {field.value?.length < MaxFilesUpload && (
                <Upload
                  p="0"
                  mb="0"
                  max={getMaxFilesUpload(field.value.length)}
                  onSelected={(selectedFiles) => setFiles(selectedFiles)}
                  files={files}
                  multiple={true}
                  title={
                    field.value?.length > 0 || files.length > 0
                      ? "Upload new files"
                      : "Upload files"
                  }
                  description={`PDF only, max ${getMaxFilesUpload(
                    field.value.length
                  )} file(s)`}
                  fileTypes={PdfUploadFileType}
                  selectedFileListTitles="The following selected files will be added"
                />
              )}
            </SimpleGrid>
          )}
        </Field>

        <Flex
          justify="center"
          flexDirection={{ sm: "row" }}
          flexGrow={{ sm: "1" }}
          gap="24px"
        >
          <Field>
            {({ form }) => {
              return (
                <RequestChangeDialog isLoading={isSubmitting} form={form} />
              );
            }}
          </Field>
        </Flex>
      </FormContainer>
    ) : (
      <Redirect to={backUrl} />
    ))
  );
}
