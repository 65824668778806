import {
  Button,
  Flex,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  createStandaloneToast,
  Textarea,
  useRadioGroup
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import moment from "moment";

import { Formik, Form, Field } from 'formik';

import {
  getSelectOptionObj,
  MomentFormatDateOnlyForSubmit,
  PdfUploadFileType,
} from "utils/Constants";

import { HSeparator } from "components/separator/Separator"
import MiniCalendar from "components/calendar/MiniCalendar";
import Upload from "components/admin/Upload";
import ReactSelect from "components/admin/ReactSelectFormik";
import Card from "components/card/Card.js";
import RadioCard from "components/radio/RadioCard";

import { getProductAvailableTimes, placeOrder, getAvailableProducts } from "apis/order"
import { filesUpload } from "apis/files"

export default function OrderForm(props) {
  const toast = createStandaloneToast();
  const [files, setFiles] = useState([])
  const [products, setProducts] = useState([]);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [productDate, setProductDate] = useState({
    unavailableDates: [],
    unavailableDateTimes: [],
    availables: []
  });

  const history = useHistory();

  const [productTimes, setProductTimes] = useState([]);
  const [quantityLimit, setQuantityLimit] = useState(1);

  const [selectedProduct, setSelectedProducts] = useState({
    id: "",
    isLimited: true,
    quantityLimit: 1
  });

  const { getRootProps, getRadioProps, setValue: setRadioValue } = useRadioGroup({
    name: "deliveryTime",
    onChange: (v) => {
      var selected = productTimes.find(p => p.time.deliveryTime == v && p.productionDate == selectedDeliveryDate)
      setSelectedDeliveryTime(selected)
      updateQuantityLimit(selected)
    }
  })
  const radioGroupProps = getRootProps();

  useEffect(() => {
    refreshProducts();
  }, []);

  function refreshProducts() {
    getAvailableProducts().then(res => {
      setProducts(res.data)
    }).catch(err => {
      console.log(err);
    })
  }

  function updateQuantityLimit(selectedDeliveryTime) {
    var productRemainingQty = productDate.remainingQty.find(d =>
      new Date(d.deliveryDate).getTime() == selectedDeliveryTime?.productionDate.getTime()
      && d.siteId == selectedDeliveryTime?.productionSiteId
      && d.timeSlotId == selectedDeliveryTime?.time.timeSlotId)
    setQuantityLimit(productRemainingQty?.remainingQty ?? selectedProduct.quantityLimit);
  }

  function getSelectableDays() {
    const selectables = [...new Set(productDate.availables.map(dt => dt.dayOfWeek))];
    return selectables;
  }

  //get the min date that an order can be placed.
  //also check if they can order on some time slots if the min date's time 
  //already passes currernt time and exclude those
  function getMinDate() {
    let minDate = new Date(new Date().setDate(new Date().getDate() + 2));

    let day = productDate.availables.find(d => d.dayOfWeek === minDate.getDay())

    if (day) {
      let deliveryTimes = day.times

      if (deliveryTimes.length > 0) {
        deliveryTimes = deliveryTimes.sort((a, b) => (a.deliveryTime > b.deliveryTime) ? 1 : ((b.deliveryTime > a.deliveryTime) ? -1 : 0));

        let [lastHour, lastMinute] = deliveryTimes[deliveryTimes.length - 1].deliveryTime.split(':');

        let minDateHour = minDate.getHours();
        let minDateMinute = minDate.getMinutes();

        if (lastHour > minDateHour || (lastHour == minDateHour && lastMinute > minDateMinute)) {
          const filtered = deliveryTimes.filter(t => {
            const [hour, minute] = t.deliveryTime.split(':')
            if (hour < minDateHour) {
              return true;
            }
            if (hour == minDateHour && minute < minDateMinute) {
              return true
            }
            return false
          }).map(f => f.timeSlotId)

          minDate.setHours(0, 0, 0, 0);
          const udt = productDate.unavailableDateTimes.find(dt => dt.siteId == day.productionSiteId && new Date(dt.date).getTime() == minDate.getTime());
          if (udt === undefined) {
            productDate.unavailableDateTimes.push({
              siteId: day.productionSiteId,
              date: minDate,
              timeSlotIds: filtered
            })
          } else {
            for (const timeSlot of filtered) {
              if (!udt.timeSlotIds.includes(timeSlot)) {
                udt.timeSlotIds.push(timeSlot)
              }
            }
          }
          setProductDate(productDate)

        } else {
          minDate.setDate(minDate.getDate() + 1)
        }
      }
    }
    minDate.setHours(0, 0, 0, 0);

    return minDate;
  }

  function getExcludedProductionDate() {
    let excludedDays = new Set();

    for (const unavailableDate of productDate.unavailableDates) {
      for (const dateTime of unavailableDate.dateTimes) {
        const otherSitesAvailabilites = productDate.availables
          .filter(a => a.productionSiteId != unavailableDate.siteId && a.dayOfWeek == new Date(dateTime).getDay());
        if (otherSitesAvailabilites.length == 0) {
          excludedDays.add(dateTime)
        } else {
          const otherSiteIds = otherSitesAvailabilites.map(o => o.productionSiteId)
          for (const otherSideId of otherSiteIds) {
            const otherUnavalible = productDate.unavailableDates.find(u => otherSideId == u.siteId)
            if (otherUnavalible) {
              if (otherUnavalible.dateTimes.includes(dateTime)) {
                excludedDays.add(dateTime)
              }
            }
          }
        }
      }
    }

    const formatted = Array.from(excludedDays).map(d => moment(d).format("YYYY-MM-DD"));
    return formatted;
  }

  function validateProductSelect(product, props) {
    if (product) {
      if (product !== selectedProduct.id) {
        const found = products.find(p => p.id === product)
        setSelectedProducts(found)

        props.setFieldValue("deliveryDate", null)
        props.setFieldValue("deliveryTime", null)
        if (found.isLimited && props.values.quantity > found.quantityLimit) {
          props.setFieldValue("quantity", 1)
        }
        getProductAvailableTimes(product)
          .then(res => {
            var availableTimes = {
              unavailableDates: res.data.unavailableDates,
              unavailableDateTimes: res.data.unavailableDateTimes,
              remainingQty: res.data.remainingQty,
              availables: res.data.rules.map(rule =>
                rule.dayTimes.map(r => {
                  return {
                    dayOfWeek: r.dayOfWeek,
                    times: r.times,
                    productionSiteId: rule.productionSiteId,
                  }
                })
              ).flat()
            }
            setProductDate(availableTimes);
          })
          .catch(err => {
            console.log(err);
          })
      }
    }
    return !product ? "Please select a product" : "";
  }

  function validateProductQuantity(quantity) {
    return ((!quantity && isNaN(quantity)) || Number(quantity) < 1) ? "Please input a quantity" : null;
  }

  function validatePatientWeight(weight) {
    return ((!weight && isNaN(weight)) || Number(weight) < 1) ? "Please input the patient's weight" : null;
  }

  function validateProductDeliveryDate(deliveryDate) {
    return !deliveryDate ? "Please select a date" : "";
  }
  function validateProductDeliveryTime(deliveryTime) {
    return !deliveryTime ? "Please select a time" : "";
  }
  function validateOrderPersonSelect(orderPerson) {
    return !orderPerson ? "Please input Order Person's name" : null;
  }
  function validatePatientName(name) {
    return !name ? "Please input patient's name" : null;
  }
  function validateDoctorName(name) {
    return !name ? "Please input doctor's name" : null;
  }
  function validateDateOfBirth(dob) {
    return !dob ? "Please select patient's date of birth" : null;
  }

  function dateTimeUnavailable(date, timeSlotId, siteId) {
    const unavailableDate = productDate.unavailableDates.find(d => d.siteId == siteId);
    if (unavailableDate) {
      if (unavailableDate.dateTimes.some(d => (new Date(d)).getTime() == date.getTime())) {
        return true
      }
    }
    const unavailableDateTimes = productDate.unavailableDateTimes.find(d => d.siteId == siteId && moment(d.date).diff(date) == 0);    
    return unavailableDateTimes?.timeSlotIds.includes(timeSlotId);
  }

  async function calendarSelectionChange(form, value) {

    await form.setFieldValue("deliveryDate", value)
    await form.setFieldValue("deliveryTime", null)
    await form.setFieldValue("quantity", 1)

    setSelectedDeliveryTime(null)
    // setQuantity(1)
    setRadioValue("")
    setSelectedDeliveryDate(value)

    const days = productDate.availables.filter(d => d.dayOfWeek === value.getDay())

    const avaliableTimes = [];
    for (let day of days) {
      for (let time of day.times) {
        if (!dateTimeUnavailable(value, time.timeSlotId, day.productionSiteId)) {
          avaliableTimes.push({
            productionSiteId: day.productionSiteId,
            productionDate: value,
            time,
          })
        }
      }
    }
    setProductTimes(avaliableTimes.sort((a, b) => (a.time.deliveryTime > b.time.deliveryTime) ? 1 : ((b.time.deliveryTime > a.time.deliveryTime) ? -1 : 0)))
  }

  return (
    <Card align='center' direction='column' w='100%' {...props}>
      <Formik
        initialValues={{
          productId: "",
          orderPerson: "",
          purchaseOrder: "",
          quantity: 1,
          patientDetails: {
            weightInKG: 1,
            name: "",
            dateOfBirth: "",
            doctorName: ""
          },
          productionSiteId: "",
          additionalInformation: "",
          files: [],
        }}

        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          const order = Object.assign({}, values);
          order.deliveryDate = moment(selectedDeliveryDate).format(MomentFormatDateOnlyForSubmit);
          order.productionSiteId = selectedDeliveryTime.productionSiteId;
          order.pickUpTime = selectedDeliveryTime.time.pickUpTime;
          order.timeSlotId = selectedDeliveryTime.time.timeSlotId;

          if (files.length > 0) {
            let form = new FormData();
            for (let file of files) {
              form.append("files", file);
            }
            filesUpload(form).then((res) => {
              order.files = res.data;
              placeOrder(order)
                .then(() => {
                  toast({
                    title: "Successful",
                    description: "Your order has been submitted",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                  history.push("/order/history")
                })
                .catch(err2 => {
                  console.log(err2)
                })
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              actions.setSubmitting(false);
            });
          } else {
            placeOrder(order)
              .then(() => {
                toast({
                  title: "Successful",
                  description: "Your order has been submitted",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                history.push("/order/history")
              })
              .catch(err => {
                console.log(err)
              }).finally(() => {
                actions.setSubmitting(false);
              });
          }
        }}
      >
        {(props) => (
          <Form>
            <Text textAlign="left" fontSize="3xl">Product Details</Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mb='20px'>
              <Field name="productId" validate={(p) => validateProductSelect(p, props)}>
                {({ field, form }) => (
                  <FormControl id="productId" isInvalid={form.errors.productId && form.touched.productId}>
                    <FormLabel htmlFor="productId">Product</FormLabel>
                    <ReactSelect
                      {...field}
                      placeholder="Please select a product"
                      options={products.map(getSelectOptionObj)}
                    />
                    <FormErrorMessage>{form.errors.productId}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="orderPerson" validate={validateOrderPersonSelect}>
                {({ field, form }) => (
                  <FormControl id="orderPerson" isInvalid={form.errors.orderPerson && form.touched.orderPerson}>
                    <FormLabel htmlFor="orderPerson">Order Person</FormLabel>
                    <Input placeholder="John Smith" {...field} />
                    <FormErrorMessage>{form.errors.orderPerson}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="purchaseOrder">
                {({ field, form }) => (
                  <FormControl id="purchaseOrder" >
                    <FormLabel htmlFor="purchaseOrder">Purchase Order</FormLabel>
                    <Input placeholder="PO123" {...field} />
                  </FormControl>
                )}
              </Field>
            </SimpleGrid>

            {props.values.productId &&
              (
                <Flex flexDirection="column" justifyContent="left" alignItems="center" my='20px' >
                  <Field name="deliveryDate" validate={validateProductDeliveryDate}
                  >
                    {({ field, form }) => (
                      <FormControl id="deliveryDate"
                        isInvalid={form.errors.deliveryDate && form.touched.deliveryDate}
                      >
                        <FormLabel textAlign="center" htmlFor="deliveryDate">Delivery Date</FormLabel>
                        <MiniCalendar
                          value={form.values.deliveryDate}
                          minDate={getMinDate()}
                          onChange={async v => await calendarSelectionChange(form, v, field)}
                          allowDays={getSelectableDays()}
                          excludedDays={getExcludedProductionDate()}
                          maxW={{ base: "100%", md: "80%", xl: "60%" }}
                          isInvalid={form.errors.deliveryDate && form.touched.deliveryDate}
                        ></MiniCalendar>
                        <FormErrorMessage justifyContent="center">{form.errors.deliveryDate}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {props.values.deliveryDate && (<Field name="deliveryTime" validate={validateProductDeliveryTime}>
                    {({ field, form }) => (
                      <FormControl id="deliveryTime"
                        isInvalid={form.errors.deliveryTime && form.touched.deliveryTime}
                      >
                        <FormLabel textAlign="center" htmlFor="deliveryTime">Delivery Time</FormLabel>
                        <Flex justifyContent="center" {...field} h='100%' flexDirection={{ base: "column", md: "row" }} {...radioGroupProps}>
                          {productTimes.map((t, i) => {
                            const radio = getRadioProps({
                              value: t.time.deliveryTime
                            })
                            return (<RadioCard {...radio} key={i}>{t.time.deliveryTime}</RadioCard>)
                          })}
                        </Flex>
                        <FormErrorMessage justifyContent="center">{form.errors.deliveryTime}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>)}

                  {props.values.deliveryTime && (<Field name="quantity" validate={validateProductQuantity}>
                    {({ field, form }) => (
                      <FormControl id="quantity" isInvalid={form.errors.quantity && form.touched.quantity} maxW={{ base: "100%", md: "80%", xl: "60%" }}>
                        <FormLabel htmlFor="quantity">Quantity
                          {selectedProduct.isLimited && ` (Up to ${quantityLimit})`}
                        </FormLabel>
                        <NumberInput
                          {...field}
                          min={1}
                          max={selectedProduct.isLimited ? quantityLimit : 9999}
                          onChange={v => props.setFieldValue("quantity", v)}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>)}
                </Flex>
              )}

            <HSeparator />

            <Text py={15} textAlign="left" fontSize="3xl">Patient's Details</Text>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px' mb='20px'>
              <Field name="patientDetails.name" validate={validatePatientName}>
                {({ field, form }) => (
                  <FormControl id="patientDetails.name" isInvalid={form.errors.patientDetails?.name && form.touched.patientDetails?.name}>
                    <FormLabel htmlFor="patientDetails.name">Name</FormLabel>
                    <Input placeholder="John Smith" {...field} />
                    <FormErrorMessage>{form.errors.patientDetails?.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="patientDetails.weightInKG" validate={validatePatientWeight}>
                {({ field, form }) => (
                  <FormControl id="patientDetails.weightInKG" isInvalid={form.errors.patientDetails?.weightInKG && form.touched.patientDetails?.weightInKG}>
                    <FormLabel htmlFor="patientDetails.weightInKG">Weight (In KG)</FormLabel>
                    <NumberInput
                      min={1}
                      {...field}
                      onChange={v => props.setFieldValue("patientDetails.weightInKG", v)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>{form.errors.patientDetails?.weightInKG}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="patientDetails.dateOfBirth" validate={validateDateOfBirth}>
                {({ field, form }) => (
                  <FormControl id="patientDetails.dateOfBirth" isInvalid={form.errors.patientDetails?.dateOfBirth && form.touched.patientDetails?.dateOfBirth}>
                    <FormLabel htmlFor="patientDetails.dateOfBirth">Date of Birth</FormLabel>
                    <Input type="date" {...field} max={moment().format("YYYY-MM-DD")} />
                    <FormErrorMessage>{form.errors.patientDetails?.dateOfBirth}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="patientDetails.doctorName" validate={validateDoctorName}>
                {({ field, form }) => (
                  <FormControl id="patientDetails.doctorName" isInvalid={form.errors.patientDetails?.doctorName && form.touched.patientDetails?.doctorName}>
                    <FormLabel htmlFor="patientDetails.doctorName">Doctor Name</FormLabel>
                    <Input placeholder="John Smith" {...field} />
                    <FormErrorMessage>{form.errors.patientDetails?.doctorName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

            </SimpleGrid>

            <HSeparator />
            <Text py={15} textAlign="left" fontSize="3xl">Additional Information</Text>

            <Field name="additionalInformation">
              {({ field, form }) => (
                <FormControl id="additionalInformation">
                  <Textarea placeholder="Other details here" {...field} />
                </FormControl>
              )}
            </Field>

            <HSeparator />

            <Upload
              minH={{
                base: "auto",
                // lg: "420px", "2xl": "365px" 
              }}
              onSelected={(selectedFiles) => setFiles(selectedFiles)}
              files={files}
              multiple={true}
              title="Upload Files"
              description="Only PDF files are allowed, max 5 files."
              fileTypes={PdfUploadFileType}
            ></Upload>

            <HSeparator />

            <SimpleGrid py={15} columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
              <Button
                colorScheme="brand"
                size="lg"
                isLoading={props.isSubmitting}
                loadingText="Submitting"
                onClick={() => {
                  props.submitForm()
                }}
              >
                Submit
              </Button>
              <Button colorScheme="brand" size="lg" type="reset" variant="outline" onClick={() => { setFiles([]) }}>Clear</Button>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
