import { Box, Flex, Text } from "@chakra-ui/react";

export default function AdminDashboard() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex pt="10" justify="center" align="center">
        <Text fontSize="4xl">Work in progress</Text>
      </Flex>
    </Box>
  );
}
