import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  TableContainer,
  Tr,
  useColorModeValue,
  useBreakpointValue,
  Select,
  IconButton,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Image,
  Link,
  VStack,
  HStack,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { NavLink } from "react-router-dom";

import Card from "components/card/Card";
import DeleteDialog from "./DeleteDialog";

import AuthImage from "components/auth/AuthImage"

function getCellData(row, cell, props) {
  const {
    disableBtnText,
    disableDialogHeader,
    disableDialogText,
    disableConfirmBtnText,
    disableColorScheme,
    enableBtnText,
    enableDialogHeader,
    enableDialogText,
    enableConfirmBtnText,
    enableColorScheme,
    viewUrl,
    disableCallback,
    displayField,
    isDisabledField,
    textColor,
  } = props;

  let data = null;
  if (cell.column.isImage && cell.value) {
    console.log(cell);
    
    data = (
      <Flex justify={{ base: "left", lg: "center" }}>
        <AuthImage
          src={cell.value}
          height="100px"
          alt={
            cell.row.original[cell.column.altText]
          }
        />
      </Flex>
    );
  } else if (cell.column.isLink && cell.value) {
    data = (
      <Flex justify={{ base: "left", lg: "center" }}>
        <Link
          // fontWeight="600"
          _hover={{ textDecoration: "underline" }}
          color="brand.500"
          href={
            cell.value.startsWith("http")
              ? cell.value
              : `//${cell.value}`
          }
          isExternal
        >
          {cell.row.values[cell.column.linkTitle] ??
            cell.column.linkTitle ??
            cell.value}
        </Link>
      </Flex>
    );
  }
  // else if (cell.column.Header === "#") {
  //   data = (
  //     <Text
  //       textAlign="center"
  //       color={textColor}
  //       fontSize="sm"
  //       fontWeight="700"
  //     >
  //       {pageIndex * pageSize + index + 1}
  //     </Text>
  //   );
  // } 
  else if (cell.column.Header === "Actions") {
    data = (
      <Flex gap="24px" justify={{ base: "left", lg: "center" }} flexDirection={{ base: "column", md: "row" }} maxW={{ base: "100px", md: "100%" }}>
        <NavLink to={viewUrl + "/" + row.original.id}>
          <Button colorScheme="brand" w="100%">View</Button>
        </NavLink>
        {disableCallback && (
          <DeleteDialog
            btnText={
              row.original[isDisabledField]
                ? enableBtnText
                : disableBtnText
            }
            dialogHeader={
              (row.original[isDisabledField]
                ? enableDialogHeader
                : disableDialogHeader) +
              row.original[displayField]
            }
            dialogText={
              (row.original[isDisabledField]
                ? enableDialogText
                : disableDialogText) +
              row.original[displayField] +
              "?"
            }
            confirmBtnText={
              row.original[isDisabledField]
                ? enableConfirmBtnText
                : disableConfirmBtnText
            }
            colorScheme={
              row.original[isDisabledField]
                ? enableColorScheme
                : disableColorScheme
            }
            onConfirm={() =>
              disableCallback(
                row.original.id,
                row.original[isDisabledField]
              )
            }
          />
        )}
      </Flex>
    );
  } else if (
    typeof cell.value === "string" ||
    typeof cell.value === "number"
  ) {
    data = (
      <Flex justify="center" align="center" overflow="hidden">
        <Text
          maxW="25vw"
          textAlign={{ base: "left", lg: "center" }}
          color={textColor}
          fontSize="sm"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"  // This ensures the overflow is hidden
        >
          {cell.value}
        </Text>
      </Flex>
    );
  } else if (typeof cell.value === "boolean") {
    data = (
      <Text
        textAlign={{ base: "left", lg: "center" }}
        color={textColor}
        fontSize="sm"
      // fontWeight="700"
      >
        {cell.value ? "Yes" : "No"}
      </Text>
    );
  } else {
    data = cell.value;
  }
  return data;
}

function DesktopView(props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    borderColor,
  } = props;

  return (
    <TableContainer>
      <Table {...getTableProps()} variant="simple" color="gray.500" scroll>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={index}
                borderColor={borderColor}
              >
                <Flex
                  justify="center"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, indexs) => {
                let data = getCellData(row, cell, props);
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={indexs}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
    </TableContainer>
  );
}

function MobileView(props) {
  const {
    page,
    prepareRow,
    textColor,
    borderColor,
  } = props;
  return (
    <VStack spacing={4} align="stretch">
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            borderColor={borderColor}
          >
            {row.cells.map((cell, indexs) => {
              let data = getCellData(row, cell, props);

              const onHeaderClick = cell.column.getSortByToggleProps().onClick;
              return (
                <SimpleGrid key={indexs} columns={2} mb={2}>
                  <Text
                    fontWeight="bold"
                    textTransform="capitalize"
                    color={textColor}
                    onClick={onHeaderClick}
                  >
                    {cell.column.Header}
                  </Text>
                  <Box flex="1" textAlign="right">
                    {data}
                  </Box>
                </SimpleGrid>
              );
            })}
          </Box>
        );
      })}
    </VStack>
  );
}

export default function ItemsTable(props) {
  const { createUrl, columnsData, tableData, createText } = props;

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns: columnsData,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    initialState,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    pageCount,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  // Determine if the screen size is small
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      {createUrl && (
        <Flex justify={"center"}>
          <NavLink to={createUrl}>
            <Button minWidth="150px" my="24px" colorScheme="brand">
              {createText}
            </Button>
          </NavLink>
        </Flex>
      )}
      <Card w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
        {data.length > 0 ? (
          !isSmallScreen ? (
            <DesktopView
              {...props}
              {...tableInstance}
              textColor={textColor}
              borderColor={borderColor}
            />
          ) : (
            <MobileView
              {...props}
              {...tableInstance}
              textColor={textColor}
              borderColor={borderColor}
            />
          )
        ) : (
          <Flex justifyContent="center" mt={4} alignItems="center">
            <Text>No data</Text>
          </Flex>
        )}
      </Card>

      {data.length > 0 && (
        <Card w="100%" mt="10px">
          <Flex
            m={4}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ base: "row" }}
            gap="15px"
          >
            <Flex>
              <Tooltip label="First Page">
                <IconButton
                  onClick={() => gotoPage(0)}
                  isDisabled={!canPreviousPage}
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label="Previous Page">
                <IconButton
                  onClick={previousPage}
                  isDisabled={!canPreviousPage}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex
              alignItems="center"
              flexDirection={{ base: "column", md: "row" }}
              gap="15px"
            >
              <Text flexShrink="0" mr={{ base: "unset", md: 8 }}>
                Page{" "}
                <Text fontWeight="bold" as="span">
                  {pageIndex + 1}
                </Text>{" "}
                of{" "}
                <Text fontWeight="bold" as="span">
                  {pageOptions.length}
                </Text>
              </Text>
              <Text flexShrink="0" display={{ base: "none", md: "block" }}>
                Go to page:
              </Text>{" "}
              <NumberInput
                display={{ base: "none", md: "block" }}
                ml={{ base: "unset", md: 1 }}
                mr={{ base: "unset", md: 1 }}
                w={28}
                min={1}
                max={pageOptions.length}
                onChange={(value) => {
                  const page = value ? value - 1 : 0;
                  gotoPage(page);
                }}
                defaultValue={pageIndex + 1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                display={{ base: "none", md: "block" }}
                w={32}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label="Next Page">
                <IconButton
                  onClick={nextPage}
                  isDisabled={!canNextPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label="Last Page">
                <IconButton
                  onClick={() => gotoPage(pageCount - 1)}
                  isDisabled={!canNextPage}
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={4}
                />
              </Tooltip>
            </Flex>
          </Flex>

          <Flex
            mb={4}
            display={{ base: "flex", md: "none" }}
            justify="center"
            alignItems="center"
            flexDirection="row"
            gap="15px"
          >
            <Text flexShrink="0">Go to page:</Text>{" "}
            <NumberInput
              w={28}
              min={1}
              max={pageOptions.length}
              onChange={(value) => {
                const page = value ? value - 1 : 0;
                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Select>
          </Flex>
        </Card>
      )}
    </>
  );
}

ItemsTable.defaultProps = {
  disableBtnText: "Disable",
  disableDialogHeader: "Disable ",
  disableDialogText: "Are you sure you want to disable ",
  disableConfirmBtnText: "Disable",
  disableColorScheme: "red",
  enableBtnText: "Enable",
  enableDialogHeader: "Enable ",
  enableDialogText: "Are you sure you want to enable ",
  enableConfirmBtnText: "Enable",
  enableColorScheme: "blue",
  displayField: "name",
  isDisabledField: "isDisabled",
  createText: "Create",
};
