import { React } from "react";

import {
  SimpleGrid,
  List,
  ListItem,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import { MdAttachFile } from "react-icons/md";
import { DeleteIcon } from "@chakra-ui/icons";

import AuthLink from "components/auth/AuthLink";

import {
  PdfUploadFileType,
  MaxFilesUpload,
} from "utils/Constants";

import Upload from "components/admin/Upload";

export default function FilesListField(props) {

  const {label, borderColor, files, setFiles, form, field, values} = props;

  console.log(label, borderColor, files, setFiles, form, field, values);
  
  function removeFile(form, field, index) {
    const { value } = field;
    value.splice(index, 1);
    form.setFieldValue(field.name, value);
  }
  function getMaxFilesUpload(fileLength) {
    return MaxFilesUpload - fileLength;
  }
  return (
    <SimpleGrid
      border="1px"
      borderColor={borderColor}
      borderRadius="5px"
      columns={{ base: 1, md: 2 }}
      gap="20px"
      my="24px"
    >
      <FormControl p="10px">
        <FormLabel>{label}</FormLabel>
        <List spacing={3}>
          {field.value?.map((file, i) => (
            <ListItem key={i}>
              <AuthLink
                href={file.name}
                icon={MdAttachFile}
                color="blue.400"
                isExternal
                isList
              >
                {file.originalName}
              </AuthLink>
              <DeleteIcon
                cursor="pointer"
                ml="5px"
                color="red.500"
                onClick={() => removeFile(form, field, i)}
              />
            </ListItem>
          ))}
        </List>
      </FormControl>

      {field.value?.length < MaxFilesUpload && (
        <Upload
          p="0"
          mb="0"
          max={getMaxFilesUpload(field.value.length)}
          onSelected={(selectedFiles) => setFiles(selectedFiles)}
          files={files}
          multiple={true}
          title={
            values?.files.length > 0 || files.length > 0
              ? "Upload new files"
              : "Upload files"
          }
          description={`PDF only, max ${getMaxFilesUpload(
            field.value.length
          )} file(s)`}
          fileTypes={PdfUploadFileType}
          selectedFileListTitles="The following selected files will be added"
        />
      )}
    </SimpleGrid>
  )
}